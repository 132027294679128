.day-container {
    width: 100%;
    position: relative;
    height: 100%;
}

.day-recipe {
    border: 1px solid black;
    border-radius: 5px;
    background-color: blue;
    color: white;
}

.add-recipe-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
}

.global-add-recipe-button {
    position: fixed;
}

.schedule-day-edit {
    position: absolute;
    top: 10px;
    right: 10px;
}

.icon-container {
    text-align: center;
    cursor: pointer;
}

.icon-container:hover {
    opacity: 0.5;
}

.schedule-day-of-week {
    margin-bottom: 0;
    font-weight: bolder;
}
