.app-card {
    padding: 10px;
    background-color: #f3f3f3;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-bottom: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.collection-select {
    max-width: 200px;
}

.add-component-button {
    margin-left: 10px;
}
