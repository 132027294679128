.recipe-list-instance {
    /*border: 1px solid rgb(150, 150, 150);*/
    border: none;
}

.modal-90w {
    width: 90%;
    max-width: unset;
}

.link-svg {
    margin-top: 6px;
}

.recipe-section {
    font-size: 0.9em;
}

.recipe-section p {
    margin-bottom: 0;
}

.recipe-section a {
    color: #0442a6;
    font-weight: bold;
}

.header-row {
    margin-top: 5px;
}